//@ts-nocheck
import React from "react";
import "./App.css";
import { ThemeProvider } from "./store/ThemeProvider";
import { GeneralProvider } from "./store/GeneralContext";
import { BrowserRouter as Router } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import UserStore from "./store/userContext";
// import Notifications from "./components/Notifications/Notifications";

// import { onMessageListener } from "./firebaseInit";
import AppRoutes from "./routes/index";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function App() {

  return (
    <div className="App">
      <UserStore>
        <Router forceRefresh={false}>
          <HelmetProvider>
            <GeneralProvider>
              <ThemeProvider>
                <ToastContainer
                  className={"toasts-container"}
                  autoClose={5000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                  theme="light"
                />
                {/* <Notifications /> */}
                <AppRoutes />
              </ThemeProvider>
            </GeneralProvider>
          </HelmetProvider>
        </Router>
      </UserStore>
    </div>
  );
}

export default App;
